<template>
  <div class="payments-block">
    <div class="card payments-block__card">
      <div class="card-body d-flex flex-column justify-content-center">
        <img
          class="payments-block__image"
          src="/media/local/outgoing-icon.png"
          alt="Incoming Icon"
        />
        <div class="payments-block__text">
          {{ text }}
        </div>
        <div class="payments-block__value">
          {{ region === "US" ? "$" : "PKR. " }}{{ separateCommas(value) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "PaymentBlock",
  props: {
    type: {
      type: String,
      enum: ["outgoing"]
    },
    currency: {
      type: String,
      default: "USD"
    },
    text: String,
    value: Number
  },
  methods: {
    ...mapGetters(["region"]),
    separateCommas: x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
};
</script>
<style lang="scss">
.payments-block {
  width: 185px;
  min-height: 150px;
  margin-right: 10px;
  &__card {
    border-radius: 15px;
    width: 100%;
  }
  &__image {
    margin: 10px auto;
    height: auto;
    width: 85px;
  }
  &__text {
    text-align: center;
    font-size: 18px;
    text-transform: capitalize;
    color: #74bbdb;
    font-weight: 600;
  }
  &__value {
    text-align: center;
    font-size: 14px;
  }
}
</style>
