<template>
  <div>
    <template v-if="region === 'US'">
      <payments-us />
    </template>
    <template v-else>
      <payments-pk />
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PaymentsUs from "@/view/pages/payments/us/Home";
import PaymentsPk from "@/view/pages/payments/pk/Home";
export default {
  name: "PaymentsMain",
  components: {
    PaymentsUs,
    PaymentsPk
  },
  computed: {
    ...mapState({
      region: ({ region }) => region.region
    })
  }
};
</script>

<style lang="sass" scoped></style>
