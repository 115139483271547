<template>
  <div>
    <b-modal
      id="order-paymen-modal"
      modal-class="modal-class"
      size="lg"
      hide-header
      hide-footer
      no-close-on-backdrop
      scrollable
    >
      <button class="close-btn" @click="hideOrderModal">
        <i class="fa fa-times"></i>
      </button>
      <div class="outgoing-payments">
        <h3 class="heading">Outgoing Payment Details</h3>

        <b-table
          hover
          :items="tableItems"
          :fields="tableFields"
          class="table-light"
          thead-class="outgoing-payments-head shadow-sm"
          tbody-class="outgoing-payments-body"
        >
          <template v-slot:cell(order_no)="data">
            <div v-if="typeof data.value === 'string'">
              {{ data.value }}
            </div>
            <!-- <div v-else-if="typeof(data.value) === 'string'"> -->
            <div
              v-else-if="typeof data.item.order_no.num === 'number'"
              data-toggle="tooltip"
              :title="data.item.order_no.orderNamitems"
            >
              {{ data.item.order_no.num }}
            </div>
          </template>

          <template v-slot:cell(customer_name)="data">
            <div v-if="typeof data.value === 'string'">
              {{ data.value }}
            </div>
            <!-- <div v-else-if="typeof(data.value) === 'string'"> -->
            <div
              v-else-if="typeof data.item.customer_name.num === 'number'"
              data-toggle="tooltip"
              :title="data.item.customer_name.customerName"
            >
              {{ data.item.customer_name.num }}
            </div>
          </template>
        </b-table>
        <!-- :items="tableItems" -->
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "OutgoingPayOrders",
  props: {
    orderDetails: Array
  },
  data() {
    return {
      tableFields: [
        {
          key: "order_Id",
          label: "Order ID",
          sortable: true
        },
        {
          key: "customer_Name",
          label: "Customer Name",
          sortable: true
        },
        {
          key: "zone",
          label: "Zone",
          sortable: true
        },
        {
          key: "order_fuel_cost",
          label: "Order Fuel Cost",
          sortable: true
        },
        {
          key: "vendor_cost",
          label: "Vendor Cost",
          sortable: true
        },
        {
          key: "company_margin",
          label: "Company Margin",
          sortable: true
        }
      ],
      tableItems: []
    };
  },
  mounted() {
    let arr = {};
    for (let i = 0; i < this.orderDetails.length; i++) {
      arr = {
        order_Id: this.orderDetails[i].orderId,
        customer_Name: this.orderDetails[i].customer,
        zone: this.orderDetails[i].zone,
        order_fuel_cost: this.orderDetails[i].fuelCost,
        vendor_cost: this.orderDetails[i].vendorCost,
        company_margin: this.orderDetails[i].companyMargin
      };
      this.tableItems.push(arr);
    }
  },
  methods: {
    showOrderModal: function() {
      this.$bvModal.show("order-paymen-modal");
    },
    hideOrderModal: function() {
      this.$bvModal.hide("order-paymen-modal");
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.close-btn {
  background: none;
  position: absolute;
  right: 25px;
  top: 15px;
}
.outgoing-payments {
  // h3.heading {
  //   text-transform: uppercase;
  // }

  ::v-deep .outgoing-payments-head {
    tr {
      th {
        color: #763fb8;
        vertical-align: middle;
        font-size: 12px;
      }
    }
  }
}
.select-PS {
  min-width: 50px;
  max-width: 120px;
  background: #283848;
  color: #e7e8ea;
  background-image: url("/media/payment/select-arrow-icon.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: 18px;
}
</style>
