<template>
  <div class="outgoing-payments">
    <vendor-payments-stats />
    <div class="row pt-2">
      <div class="col-lg-6 py-3">
        <h3 class="heading">Outgoing Vendor Payments</h3>
        <button
          @click="refresh"
          class="create-button"
          :class="fetchingRecords ? 'btn-disabled processing' : ''"
          :disabled="fetchingRecords"
        >
          Refresh
          <img
            class="ajax-loader"
            src="/media/ajax/white.gif"
            alt="Ajax loader"
          />
        </button>
      </div>
      <div class="col-lg-3">
        <div style="display: grid; place-items: center" class="w-100 h-100">
          <span><b>Total Payment Records:</b> {{ rows }}</span>
        </div>
      </div>
      <div
        class="col-lg-3 d-flex align-items-center justify-content-end py-3"
        v-if="rows > 10"
      >
        <div class="row w-100">
          <span class="col-4 d-flex align-items-center">Payments Per Page</span>
          <b-form-select
            v-model="perPage"
            :options="perPageOptions"
            class="col-8"
            @change="perPageChange"
          ></b-form-select>
        </div>
      </div>
      <div class="col-lg-12 table-container">
        <template v-if="networkError === null">
          <b-table
            hover
            :items="records"
            :fields="tableFields"
            :busy="fetchingRecords"
            class="table-light"
            @sort-changed="sortChanged"
            thead-class="outgoing-payments-head shadow-sm"
            tbody-class="outgoing-payments-body"
            @row-clicked="orderRowClicked"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle mx-4"></b-spinner>
                <strong>Fetching...</strong>
              </div>
            </template>
            <template v-slot:cell(order_no)="data">
              {{ data.value }}

              <!-- <div v-else-if="typeof(data.value) === 'string'"> -->
            </template>

            <template v-slot:cell(customers)="data">
              {{ data.value }}
            </template>
            <template v-slot:cell(status)="data">
              <!-- <span class="status"> -->
              <span
                v-if="data.value === 'requested'"
                class="status status__requested"
              >
                {{ data.value }}
              </span>

              <span
                v-else-if="data.value === 'pending'"
                class="status status__pending"
              >
                {{ data.value }}
              </span>

              <span
                v-else-if="data.value === 'verified'"
                class="status status__verified"
              >
                {{ data.value }}
              </span>
              <!-- </span> -->
            </template>
          </b-table>
        </template>
        <template v-else>
          <div class="text-danger text-center display-4 p-4">API Error</div>
          <div class="text-danger text-center lead pb-4">
            Error while fetchting orders history. Please refresh to try again.
          </div>
          <div class="text-danger text-center lead pb-4">
            {{ networkError }}
          </div>
        </template>
      </div>
      <template v-if="rows > 0">
        <div class="col-lg-12 d-flex justify-content-center">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="active-orders-table"
            :current-page="currentPage"
            align="fill"
            first-number
            last-number
            @change="changePage"
          ></b-pagination>
        </div>
      </template>
    </div>

    <!-- :items="tableItems" -->
    <template v-if="modalData !== null">
      <OutgoingPayOrders
        ref="orderOutgoingModal"
        :orderDetails="modalData"
        @close="outgoingPaymentModalClosed"
      />
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import VendorPaymentsStats from "@/view/pages/payments/pk/VendorPaymentsStats";
import OutgoingPayOrders from "@/view/pages/payments/OutgoingPayOrders";
import { GetVendorPayments } from "@/core/services/store/payments.module";

export default {
  name: "VendorPaymentsPK",
  components: {
    OutgoingPayOrders,
    VendorPaymentsStats
  },
  data() {
    return {
      modalData: null,
      responseData: "",
      currentPage: 1,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" }
      ],
      sortBy: "-date",
      tableFields: [
        {
          key: "name",
          label: "Name",
          sortable: false
        },
        {
          key: "orders",
          label: "No. of Orders",
          sortable: false
        },
        {
          key: "date",
          label: "Date",
          sortable: true
        },
        {
          key: "customers",
          label: "Customers",
          sortable: false
        },
        {
          key: "amount",
          label: "Payment Amount",
          sortable: true
        },
        {
          key: "status",
          label: "Payment Status",
          sortable: true
        }
      ],
      perPage: 10
    };
  },
  methods: {
    async sortChanged(ctx) {
      this.sortBy = `${ctx.sortDesc ? "-" : ""}${ctx.sortBy}`;
      console.log(
        "file: VendorPayments.vue | line 193 | sortChanged | this.sortBy",
        this.sortBy
      );
      this.$store.dispatch(GetVendorPayments, {
        limit: [(this.currentPage - 1) * this.perPage, this.perPage],
        sort: this.sortBy
      });
    },
    orderRowClicked(items) {
      this.modalData = this.OutgoingPayments[items.index].orders;
      setTimeout(() => {
        this.$refs.orderOutgoingModal.showOrderModal();
      }, 100);
    },
    outgoingPaymentModalClosed() {
      this.modalData = null;
    },
    async perPageChange(val) {
      await this.updateOrders();
      this.perPage = val;
    },
    updateOrders(options = {}) {
      options.limit = [(this.currentPage - 1) * this.perPage, this.perPage];
      options.sort = this.sortBy;
      this.$store.dispatch(GetVendorPayments, options);
    },
    refresh() {
      this.$store.dispatch(GetVendorPayments, {
        limit: [(this.currentPage - 1) * this.perPage, this.perPage],
        sort: this.sortBy
      });
    },
    async changePage(val) {
      let obj = {};
      obj.limit = [(val - 1) * this.perPage, this.perPage];
      obj.sort = this.sortBy;
      await this.$store.dispatch(GetVendorPayments, obj);
      this.currentPage = val;
    }
  },
  computed: {
    ...mapState({
      fetchingRecords: ({ payments }) => payments.vendorPaymentsLoading,
      rows: ({ payments }) => payments.vendorPaymentsCount,
      vendorPayments: ({ payments }) => payments.vendorPayments,
      networkError: ({ payments }) => payments.vendorPaymentsError,
      records: ({ payments }) => {
        const { vendorPayments: recs } = payments;

        return recs.length < 1
          ? []
          : recs.map((item, index) => ({
              index,
              name: item.store.storeName,
              orders: item.orders.length,
              date: moment(parseInt(item.createdAt)).format(
                "MMMM Do YYYY, h:mm a"
              ),
              customers: item.orders.length,
              amount: item.amount,
              status: item.status
            }));
      }
    })
  },
  mounted() {
    this.$store.dispatch(GetVendorPayments, {
      limit: [0, 10]
    });
    setTimeout(() => {
      this.x = 10;
    }, 5000);
  }
};
</script>

<style lang="scss" scoped>
.outgoing-payments {
  // h3.heading {
  //   text-transform: uppercase;
  // }
  ::v-deep .outgoing-payments-head {
    tr {
      th {
        color: #763fb8;
        vertical-align: middle;
        font-size: 12px;
      }
    }
  }
  .status {
    color: #fff;
    background: #916266;
    padding: 5px 15px;
    margin-left: 10px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    &__pending {
      background: #dc1828;
    }
    &__requested {
      background: #283848;
    }
    &__verified {
      background: #4ff86f;
    }
  }
}
.select-PS {
  min-width: 50px;
  max-width: 120px;
  background: #283848;
  color: #e7e8ea;
  background-image: url("/media/payment/select-arrow-icon.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: 18px;
}
</style>
